<template>
  <div class="card">
    <div class="card-body">
      <hubspot-form :license="license" v-if="license && license.email" />
      <spinner-component v-else />
    </div>
  </div>
</template>

<script>
import { computed, defineAsyncComponent, ref } from 'vue'
import { store } from '@/application/store'

export default {
  name: 'CommittedUseDiscount',
  components: {
    'hubspot-form': defineAsyncComponent(() => import('@/application/components/forms/CommitedUseDiscountForm.vue')),
    'spinner-component': defineAsyncComponent(() => import('@/application/components/layout/SpinnerComponent.vue')),
  },
  setup() {
    const license = computed(() => store.getters.getLicense)
    const showSpinner = ref(true)
    return {
      showSpinner,
      license,
    }
  },
}

</script>
